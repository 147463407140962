<script setup lang="ts">
// Props
const emit = defineEmits<{
  (e: 'click', val: number | string): void;
}>();
defineProps<{
  listTabs: { label: string; id: number | string }[];
  tabName?: string;
  disabledTabs?: number[];
}>();

const modelValue = defineModel<number | string | null>();

// Methods
const click = (id: string | number) => {
  modelValue.value = id;
  emit('click', id);
};
</script>

<template>
  <div class="flex gap-2 overflow-x-auto relative">
    <button
      v-for="(tab, index) in listTabs"
      :key="tab.id"
      class="group px-2 py-1 z-10 relative cursor-pointer whitespace-nowrap text-sm"
      :class="[
        modelValue === tab.id
          ? 'font-medium text-[#1ba774] rounded-md bg-primary-100'
          : 'border-transparent text-gray-500 hover:text-[#1BA774] hover:border-gray-300 ',
      ]"
      @click="click(tab.id)"
    >
      <slot :name="index" :tab="tab">
        {{ tab?.label }}
      </slot>
    </button>
  </div>
</template>
